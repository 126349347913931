<template>
  <div>
    <div class="middleEscalatorAnimateArea" :style="{'width':bWidth,'height':bHeight}">
      <!--标题栏-->
      <div
        style="top: 0; background: RGB(99,108,147); opacity: 0.8; width:100%; height: 25px; line-height: 25px; text-align: center">
        <a style="cursor: pointer;" @click="openMonitor()">{{name}}</a>
      </div>
      <!--扶梯动图-->
      <div
        class="em-monitor"
        :class="{'em-monitor-slow': monitorSpeed==='慢', 'em-monitor-down': monitorData.Operation_Direction===2, 'em-monitor-run': monitorRun}"></div>
      <!--运行状态图-->
      <div class="em-detail">
        <div>
          <img :src="monitorStatusIcon" style="width: 80px;"/>
        </div>
        <div class="runStatus">
          <div class="runTitle">运行状态</div>
          <div class="runContent">{{monitorSpeed}}</div>
        </div>
      </div>
      <!--重连按钮-->
      <div class="em-connect-status-wrapper">
        <div v-if="wsShowTip" style="color: #ffffff; font-size: 14px; line-height: 30px;">
          <i class="el-icon-loading"></i>{{wsTipText}}
        </div>
        <div v-if="wsShowRetry" style="line-height: 50px;">
          <el-button type="primary" icon="el-icon-refresh" @click="createWs">
            {{$t("monitor.retry")}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const wsUrl = window.config.wsUrl;
  export default {
    components: {},
    /* eslint-disable */
    props: ["registerNo", "name", "bWidth", "bHeight", "clickTag"],
    /* eslint-enable */
    data() {
      return {
        ws: null,
        wsShowTip: false,
        wsShowRetry: false,
        wsTipText: "连接中...",
        monitorData: {
          fault: 0,
          repair: 0,
          fire: 0,
          leisureStop: 0,
          leisureSlow: 0,
          Service_Mode: 0,
          Operation_Status: 0,
          Operation_Direction: -1,
        },
        liveData: {
          d00: "",
          d01: "",
          d03: "",
          d05: "",
          d06: "",
          d07: "",
          d0A: "",
          d0E: "",
          d10: "",
          d14: "",
          d15: "",
          d19: "",
          d37: "",
          d38: "",
          d3D: "",
        },
      };
    },
    computed: {
      monitorRun() {
        return this.monitorData.Operation_Status === 1 && this.monitorData.Operation_Direction !== 0 && this.monitorData.leisureStop !== 1;
      },
      monitorSpeed() {
        if (!this.monitorRun) {
          return "停止";
        }
        return this.monitorData.leisureSlow ? "慢速" : "快速";
      },
      monitorStatusIcon() { // 消防 检修 故障
        let path = "/static/images/escalatorMonitor/";
        if (this.monitorData.fault === 1) {
          return path + "fault.png";
        }
        if (this.monitorData.repair === 1) {
          return path + "repair.png";
        }
        if (this.monitorData.fire === 1) {
          return path + "fire.png";
        }
        if (this.monitorData.Operation_Direction === 0) {
          return path + "stop.png";
        }
        if (this.monitorData.Operation_Direction === 1) {
          return path + "up.png";
        }
        if (this.monitorData.Operation_Direction === 2) {
          return path + "down.png";
        }
        return path + "stop.png";
      },
    },
    watch: {
      "Car_Direction": "changeUpDown",
      "Car_Status": "changeUpDown",
      "Door_Switch_Status": "changeDoor",
      "clickTag": "createWs",
      "Fault_Status": "changeFaultStatus",
      "passengerStatus": "changePassengerStatus",
    },
    mounted() {
      this.createWs();
    },
    destroyed() {
      this.ws.close();
    },
    methods: {
      createWs() {
        this.wsShowTip = false;
        this.wsShowRetry = false;
        try {
          this.ws = new WebSocket(wsUrl);
          this.initWsEvent();
        } catch (e) {
          this.wsTipText = this.$t("monitor.error");
          this.showRetry();
        }
      },
      showRetry() {
        this.wsShowTip = true;
        this.wsShowRetry = true;
      },
      initWsEvent() {
        this.ws.onopen = () => {
          this.wsShowTip = true;
          this.wsTipText = this.$t("monitor.escalatorConnect");
          let authProto = {
            t: "B00",
            d: {regCode: this.registerNo},
          };
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onclose = () => {
          this.showRetry();
        };
        this.ws.onerror = () => {
          this.wsTipText = this.$t("monitor.error");
          this.showRetry();
        };
        this.ws.onmessage = (event) => {
          console.log("onmessage", JSON.parse(event.data));
          try {
            let proto = JSON.parse(event.data);
            let type = proto.t;
            let beforeNum;
            switch (type) {
              case "A05":
                beforeNum = proto.d.beforeNum;
                this.wsTipText = this.$t("monitor.a05Describe") + beforeNum;
                break;
              case "A06":
                this.wsShowTip = true;
                this.wsTipText = this.$t("monitor.a06Describe");
                break;
              case "A08":
                this.wsShowTip = true;
                this.wsTipText = this.$t("monitor.a08Describe");
                break;
              case "A10":
                this.wsShowTip = true;
                this.wsTipText = this.$t("monitor.a10Describe");
                break;
              case "A11":
                this.wsShowTip = true;
                this.wsTipText = this.$t("monitor.a11Describe");
                break;
              case "A09":
                this.monitorData = proto.d;
                if (this.monitorData.fault === 1) {
                  this.wsShowTip = true;
                  // let faultCode = this.monitorData.faultCode + "";
                  // this.wsTipText = "发生故障-代码" + (faultCode || "未知");
                  this.wsTipText = "发生故障";
                } else if (this.monitorData.repair === 1) {
                  this.wsShowTip = true;
                  this.wsTipText = this.$t("monitor.status.service");
                } else if (this.monitorData.fire === 1) {
                  this.wsShowTip = true;
                  this.wsTipText = this.$t("monitor.status.fire");
                } else {
                  this.wsShowTip = false;
                }
                break;
              case "B4":
                this.liveData = proto.d;
                break;
            }
          } catch (e) {
            console.log(e);
          }
        };
      },
      openMonitor() {
        this.loading = true;
        let params = {
          "elevatorRegisterNo": this.registerNo,
        };
        this.$api.getList("/singleLadderMonitor/elevatorRegisterNo", params).then(res => {
          let elevatorId = res.data.id;
          window.open("#/singleMonitor/" + elevatorId);
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.middleEscalatorAnimateArea {
  margin: 0px;
  background: url(/static/images/escalatorMonitor/bg.jpg) no-repeat;
  background-size: 100% 100%;
  position: relative;

  .em-monitor {
    position: absolute;
    width: 98%;
    height: 90%;
    background: url(/static/images/escalatorMonitor/animate.png) no-repeat 20px 0px;
  }

  .em-monitor-run {
    animation: monitor-animate 0.6s step-start infinite;
    -moz-animation: monitor-animate 0.6s step-start infinite;
  }

  .em-monitor-slow {
    animation-duration: 2s;
    -moz-animation-duration: 2s;
  }

  .em-monitor-down {
    animation-direction: reverse;
    -moz-animation-direction: reverse;
  }

  .em-detail {
    position: absolute;
    right: 5%;
    top: 20%;

    .runStatus {
      margin: 2% 0 0 6px;
      width: 69px;
      height: 69px;
      border: none;
      background-color: white;
      font-size: 12px;
      border-radius: 5px;
      text-align: center;

      .runTitle {
        line-height: 28px;
      }

      .runContent {
        line-height: 41px;
        height: 41px;
        font-size: 16px;
        border-radius: 5px;
        background-color: #368de6;
        color: white;
      }
    }
  }

  .em-connect-status-wrapper {
    position: absolute;
    top: 38%;
    left: 20%;
    width: 50%;
    height: 80px;
    text-align: center;
  }

  @keyframes monitor-animate {
    0% {
      background-position: 0 0;
    }
    12.5% {
      background-position: -535px 0;
    }
    25% {
      background-position: -1070px 0;
    }
    37.5% {
      background-position: -1605px 0;
    }
    50% {
      background-position: -2140px 0;
    }
    62.5% {
      background-position: -2675px 0;
    }
    75% {
      background-position: -3210px 0;
    }
    87.5% {
      background-position: -3745px 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}
</style>
