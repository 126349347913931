import { render, staticRenderFns } from "./smallEscalatorElevator.vue?vue&type=template&id=6039ba65&scoped=true"
import script from "./smallEscalatorElevator.vue?vue&type=script&lang=js"
export * from "./smallEscalatorElevator.vue?vue&type=script&lang=js"
import style0 from "./smallEscalatorElevator.vue?vue&type=style&index=0&id=6039ba65&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6039ba65",
  null
  
)

export default component.exports