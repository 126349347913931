<template>
  <div class="wrap" style="background-color: #404040">
    <div class="querypage">
      <div class="header">
        <img class="large" :src="$tenant.bigLogo" style="height: 40px;margin-left: 70px;margin-top: 10px"/>
      </div>
      <div class="monitorcontainer" style="margin-left: 70px;">
        <div class="add" style="width: 1100px; top: 70px; position: absolute;">
          {{ $t("monitor.mark.position") }} &gt;<a style="cursor: pointer;">{{ $t("route.useUnitMonitor") }}</a>
        </div>
        <div id="container2" class="contentright" style="background-color: rgb(235, 235, 235); height: 100%">
          <div>
            <div class="rightbox_dt_big" style="position:relative;width:1100px">
              <div style="position:absolute;z-index:1;left: 30px;top: 20px;">
                <!--一个电梯-->
                <table v-if="selectElevatorSize === 1" class="table1"
                  style="margin-left: 100px; margin-top: 20px; width: 100%;">
                  <tr>
                    <td class="table1" align="right">
                      <middle-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag"></middle-escalator-elevator>
                      <middle-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag" :layout="1"></middle-elevator>
                    </td>
                  </tr>
                </table>
                <!--两个-->
                <table v-else-if="selectElevatorSize === 2" class="table2" style="margin-top: 20px">
                  <tr>
                    <td class="table2">
                      <middle-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag"></middle-escalator-elevator>
                      <middle-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag" :layout="2"></middle-elevator>
                    </td>
                    <td class="table2">
                      <middle-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag"></middle-escalator-elevator>
                      <middle-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag" :layout="2"></middle-elevator>
                    </td>
                  </tr>
                </table>
                <!--三个-->
                <table v-else-if="selectElevatorSize === 3" class="table3" style="margin-left: 100px">
                  <tr>
                    <td rowspan="2">
                      <middle-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag"></middle-escalator-elevator>
                      <middle-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag" :layout="3"></middle-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[2].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                </table>
                <!--四个-->
                <table v-else-if="selectElevatorSize === 4" class="table4" style="margin-left: 100px">
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :monitor-limit="elevator[1].monitorLimit"
                        :name="elevator[1].name" :b-width="smallElevatorWidth" :b-height="smallElevatorHeight"
                        :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[2].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[3].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                </table>
                <!--五个-->
                <table v-else-if="selectElevatorSize === 5" class="table5" style="margin-left: 40px">
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td rowspan="2">
                      <middle-escalator-elevator v-if="elevator[2].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag"></middle-escalator-elevator>
                      <middle-elevator v-else :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="middleElevatorWidth"
                        :b-height="middleElevatorHeight" :click-tag="clickTag" :layout="5"></middle-elevator>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[3].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[4].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                </table>
                <!--6个-->
                <table v-else-if="selectElevatorSize === 6" class="table6" style="margin-left: 40px">
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[2].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[3].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[4].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[5].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[5].registerNo" :name="elevator[5].name"
                        :monitor-limit="elevator[5].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[5].registerNo" :name="elevator[5].name"
                        :monitor-limit="elevator[5].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                </table>
                <!--7个-->
                <table v-else-if="selectElevatorSize === 7" class="table7" style="margin-left: 60px">
                  <tr>
                    <td colspan="1.4">
                      <small-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td colspan="1.3">
                      <small-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td colspan="1.3">
                      <small-escalator-elevator v-if="elevator[2].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[3].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[4].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[5].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[5].registerNo" :name="elevator[5].name"
                        :monitor-limit="elevator[5].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[5].registerNo" :name="elevator[5].name"
                        :monitor-limit="elevator[5].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[6].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[6].registerNo" :name="elevator[6].name"
                        :monitor-limit="elevator[6].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[6].registerNo" :name="elevator[6].name"
                        :monitor-limit="elevator[6].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                </table>
                <!--8个-->
                <table v-else-if="selectElevatorSize >= 8" class="table8" style="margin-left: 60px">
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[0].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[0].registerNo" :name="elevator[0].name"
                        :monitor-limit="elevator[0].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[1].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[1].registerNo" :name="elevator[1].name"
                        :monitor-limit="elevator[1].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[2].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[2].registerNo" :name="elevator[2].name"
                        :monitor-limit="elevator[2].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[3].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[3].registerNo" :name="elevator[3].name"
                        :monitor-limit="elevator[3].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small-escalator-elevator v-if="elevator[4].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[4].registerNo" :name="elevator[4].name"
                        :monitor-limit="elevator[4].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[5].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[5].registerNo" :name="elevator[5].name"
                        :monitor-limit="elevator[5].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[5].registerNo" :name="elevator[5].name"
                        :monitor-limit="elevator[5].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[6].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[6].registerNo" :name="elevator[6].name"
                        :monitor-limit="elevator[6].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[6].registerNo" :name="elevator[6].name"
                        :monitor-limit="elevator[6].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                    <td>
                      <small-escalator-elevator v-if="elevator[7].elevatorProductTypeCode === '3310'"
                        :register-no="elevator[7].registerNo" :name="elevator[7].name"
                        :monitor-limit="elevator[7].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-escalator-elevator>
                      <small-elevator v-else :register-no="elevator[7].registerNo" :name="elevator[7].name"
                        :monitor-limit="elevator[7].monitorLimit" :b-width="smallElevatorWidth"
                        :b-height="smallElevatorHeight" :click-tag="clickTag"></small-elevator>
                    </td>
                  </tr>
                </table>
              </div>
              <div
                style="position:absolute;z-index:2;left: 30px;top: 20px;background-image: url(/static/images/monitorCommon/big-diwen.png)">
                <img v-if="showTag === false" src="/static/images/monitorCommon/jt_open.png"
                  @click="clickShowElevator()"/>
                <img v-if="showTag === true" style="margin-left: 493px" src="/static/images/monitorCommon/jt_close.png"
                  @click="clickShowElevator()"/>
                <el-transfer v-if="showTag === true" v-model="value1" filterable :titles="titles"
                  :button-texts="$t('lang') === 'zh' ? ['到左边', '到右边'] : ['To the left', 'To the right']" :data="data">
                  <div slot="left-footer" class="transfer-footer"></div>
                  <el-button slot="right-footer" class="transfer-footer" @click="handleSubmit">
                    {{ $t("common.operation") }}
                  </el-button>
                </el-transfer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">

      </div>
    </div>
  </div>
</template>
<script>
import smallElevator from "./smallElevator";
import middleElevator from "./middleElevator";
import smallEscalatorElevator from "./smallEscalatorElevator";
import middleEscalatorElevator from "./middleEscalatorElevator";

export default {
  components: { middleElevator, smallElevator, smallEscalatorElevator, middleEscalatorElevator },
  data() {
    return {
      copyright: window.config.copyright,
      data: [],
      value1: [],
      showTag: false,
      elevator: [],
      selectElevatorSize: 0,
      middleElevatorWidth: "",
      middleElevatorHeight: "",
      smallElevatorWidth: "",
      smallElevatorHeight: "",
      useUnitName: "",
      titles: [],
      clickTag: true,
    };
  },
  mounted() {
    let buildingNum = this.$route.params.buildingNum;
    this.useUnitName = this.$route.params.useUnitName;
    this.titles.push(this.useUnitName);
    let params = {
      "useUnitId": this.$route.params.useUnitId,
    };
    this.$api.getList("/useUnitMonitor/elevators/buildingNo", params).then(res => {
      let elevatorList = res.data;
      for (let i = 0; i < elevatorList.length; i++) {
        if (elevatorList[i].regNo === "") {
          continue;
        }
        if (this.$t("lang") === "zh") {
          this.data.push({
            key: elevatorList[i].regNo,
            elevatorProductTypeCode: elevatorList[i].elevatorProductTypeCode,
            label: elevatorList[i].buildingNo + "号楼" + elevatorList[i].ladderNo + "号梯",
            monitorLimit: elevatorList[i].monitorLimit,
          });
        } else {
          this.data.push({
            key: elevatorList[i].regNo,
            elevatorProductTypeCode: elevatorList[i].elevatorProductTypeCode,
            label: "Building " + elevatorList[i].buildingNo + " Lift " + elevatorList[i].ladderNo,
            monitorLimit: elevatorList[i].monitorLimit,
          });
        }
        if (elevatorList[i].buildingNo === buildingNum) {
          this.value1.push(elevatorList[i].regNo);
        }
      }
      this.handleSubmit();
    }).catch(() => {
      this.$message.error(this.$t("common.tip.getDataError"));
    });
  },
  methods: {
    handleSubmit() {
      this.clickTag = !this.clickTag;
      this.selectElevatorSize = this.value1.length;
      if (this.selectElevatorSize > 8) {
        this.$message.error(this.$l("elevator.monitorElevators", "同时最多监控8个电梯"));
      } else {
        this.elevator = [];
        if (this.selectElevatorSize === 1) {
          this.middleElevatorWidth = "600px";
          this.middleElevatorHeight = "500px";
        }
        if (this.selectElevatorSize === 2) {
          this.middleElevatorWidth = "500px";
          this.middleElevatorHeight = "500px";
        }
        if (this.selectElevatorSize === 3) {
          this.middleElevatorWidth = "600px";
          this.middleElevatorHeight = "551px";
          this.smallElevatorWidth = "200px";
          this.smallElevatorHeight = "275px";
        }
        if (this.selectElevatorSize === 4) {
          this.smallElevatorWidth = "400px";
          this.smallElevatorHeight = "275px";
        }
        if (this.selectElevatorSize === 5) {
          this.middleElevatorWidth = "350px";
          this.middleElevatorHeight = "551px";
          this.smallElevatorWidth = "300px";
          this.smallElevatorHeight = "275px";
        }
        if (this.selectElevatorSize === 6) {
          this.smallElevatorWidth = "300px";
          this.smallElevatorHeight = "275px";
        }
        if (this.selectElevatorSize === 7) {
          this.smallElevatorWidth = "210px";
          this.smallElevatorHeight = "275px";
        }
        if (this.selectElevatorSize === 8) {
          this.smallElevatorWidth = "210px";
          this.smallElevatorHeight = "275px";
        }
        for (let i = 0; i < this.value1.length; i++) {
          for (let j = 0; j < this.data.length; j++) {
            if (this.data[j].key === this.value1[i]) {
              this.elevator.push({
                "registerNo": this.value1[i],
                "name": this.data[j].label,
                "elevatorProductTypeCode": this.data[j].elevatorProductTypeCode,
                "monitorLimit": this.data[j].monitorLimit,
              });
            }
          }
        }
        this.showTag = false;
      }
    },
    clickShowElevator() {
      this.showTag = !this.showTag;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/monitorSingle.css";
</style>
